<template>
  <div class="container">
    <div class="header">
      <van-icon
          class="header-return"
          name="arrow-left"
          size="25"
          color="#bcbec0"
          @click="onClickHeaderReturn()"
      />

      {{ $route.params.title }}
    </div>

    <div>
      <div v-if="$route.params.type === 1" class="privacy-policy">
        <p><strong>PRIVACY POLICY</strong></p>
        <p>Last updated December 15, 2023</p>
        <p><strong>1. AGREEMENT TO LEGAL TERMS</strong></p>
        <p>We are IVISION TECH INC.  ("<strong>Company</strong>,"
          "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>"), a
          company registered and located at 11818 Clark St., Building A, Arcadia,
          California 91006.</p>
        <p>We operate the mobile application OMNI
          Connector (the "<strong>App</strong>"), as well as other related
          products and services that refer or link to these legal terms
          (the "<strong>Legal Terms</strong>") (collectively,
          the "<strong>Services</strong>").</p>
        <p>You can contact us by phone at (626) 256-3449, email
          at ivisiontechinc@gmail.com, or by mail to 11818 Clark St., Building A,
          Arcadia, California 91006, United States.</p>
        <p>By downloading the App and connecting your vape device to your phone,
          via NFC or otherwise, or by using the App, you agree to abide by these
          Legal Terms. Users may be required to create an account to gain access
          to certain features of the App; however, creation of an account is not a
          prerequisite to your agreement to abide by these Legal Terms.</p>
        <p>These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity
          ("<strong>you</strong>" and the possessive <strong>“your”</strong>),
          and IVISION TECH INC. concerning your access to and use of the Services.
          You agree that by accessing the Services, you have read, understood, and
          agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
          ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
          THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
        <p>Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated herein
          by reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Legal Terms from time to time. We will
          alert you about any changes by updating the "Last updated" date of these
          Legal Terms, and you waive any right to receive specific notice of each
          such change. It is your responsibility to periodically review these
          Legal Terms to stay informed of updates. You will be subject to, and
          will be deemed to have been made aware of and to have accepted, the
          changes in any revised Legal Terms by your continued use of the Services
          after the date such revised Legal Terms are posted.</p>
        <p>The Services are intended for users who are at least 21 years old.
          Persons under the age of 21 are not permitted to use or register for the
          Services.</p>
        <p>We recommend that you print a copy of these Legal Terms for your
          records.</p>
        <p>IVISION TECH INC. is committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy Policy
          explains how we collect, use, disclose, and protect your personal data
          when you access or use our OMNI mobile application and related services
          ("Services"). By accessing or using our Services, you consent to the
          practices described in this Privacy Policy.</p>
        <p><strong>2. DATA WE COLLECT</strong></p>
        <p>We may collect various types of information, including but not
          limited to:</p>
        <p><strong>2.1 Personal Information</strong></p>
        <p>Personal data, or personal information, means any information about
          an individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).</p>
        <p>We may collect, use, store, and transfer different kinds of personal
          data about you which we have grouped together as follows:</p>
        <ul>
          <li><p><strong>• Identity Data:</strong> First name, last name, username,
            date of birth, and gender.</p></li>
          <li><p><strong>• Contact Data:</strong> Billing address, delivery address,
            email address, and telephone numbers.</p></li>
          <li><p><strong>• Technical Data:</strong> Internet protocol (IP) address,
            login data, browser type, device information, and more.</p></li>
          <li><p><strong>• Profile Data:</strong> User preferences, feedback, survey
            responses, and other user-generated content.</p></li>
          <li><p><strong>• Usage Data:</strong> Information about how you use our
            app, products, and services.</p></li>
          <li><p><strong>• Marketing and Communications Data:</strong> Your
            preferences in receiving marketing communications.</p></li>
        </ul>
        <p><strong>2.2 Aggregated Data</strong></p>
        <p>We also collect, use, and share Aggregated Data such as statistical
          or demographic data for any purpose. Aggregated Data may be derived from
          your personal data but is not considered personal data as this data does
          not directly or indirectly reveal your identity. For example, we may
          aggregate your Usage Data to calculate the percentage of users accessing
          a specific app feature, or the number of customers using the same power
          curve. However, if we combine or connect Aggregated Data with your
          personal data so that it can directly or indirectly identify you, we
          treat the combined data as personal data which will be used in
          accordance with this privacy policy.</p>
        <p>We do not collect any Special Categories of Personal Data about you
          (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political opinions,
          trade union membership, information about your health, genetic, and
          biometric data). Nor do we collect any information about criminal
          convictions and offences.</p>
        <p><strong>3. HOW OMNI COLLECTS YOUR PERSONAL DATA</strong></p>
        <p><strong>3.1 Online Data Collection</strong></p>
        <p><strong>(a) Visitors to our APP or websites</strong><br />
          When you visit our APP or websites, we utilize third-party service
          providers, including Google Analytics and Adjust, to gather standard
          internet log information and observe visitor behavior patterns. This
          practice helps us understand various aspects such as the number of
          visitors to different sections of the APP or websites. Please note that
          this information is processed in a way that does not identify any
          specific individuals. We do not make any attempts to uncover the
          identities of our website visitors. If you decide to submit personal
          information through the website, such as when purchasing goods or
          services, signing up for newsletters, participating in surveys, or
          entering competitions, this policy outlines our intended use of such
          data.</p>
        <p><strong>(b) Cookies</strong><br />
          Our website uses cookies, which are small files containing letters and
          numbers that we place on your computer with your consent. These cookies
          enable us to differentiate you from other users of our APP and website,
          enhancing your browsing experience, and providing insights to improve
          our site. To learn more about the specific cookies we employ and how to
          identify them, please refer to our cookie policy [link to cookie
          policy].</p>
        <p><strong>(c) Search Engine</strong><br />
          We log search queries and the results of searches on our website in an
          anonymous manner. This practice aids us in enhancing our website's
          functionality and search capabilities. Please be aware that we do not
          collect any user-specific data during this process.</p>
        <p><strong>(d) Security and Performance</strong><br />
          To maintain the security and performance of our APP and websites, we
          rely on a third-party service that processes the IP addresses of our
          visitors and other Technical Data. Rest assured that this information is
          retained for a maximum period of 24 months.</p>
        <p><strong>(e) Online Accounts</strong><br />
          When you establish an online account with us, we will request personal
          data from you for the purpose of identity verification and account
          setup. This information will also be utilized during your interactions
          with us through the APP or websites. We retain this information for a
          minimum of 5 years from your last login to the service, unless you have
          made purchases through our APP or websites or opted in to receive
          marketing communications. In such cases, these accounts will remain
          active for as long as we retain the associated information, consistent
          with the rest of this policy.</p>
        <p><strong>(f) Children's Privacy</strong><br />
          OMNI is intended for adults only, and we do not knowingly collect data
          related to children. Individuals under the age of twenty-one (21) are
          prohibited from using our App and Sites and should refrain from
          submitting any information about themselves to us. By using our app or
          website, you represent that you are at least twenty-one (21) years old,
          acknowledging that you must meet this age requirement to create an
          account and use our services. Any information associated with an account
          that we become aware is linked to a registered user under twenty-one
          (21) years old will be promptly deleted.</p>
        <p><strong>3.2 Third-party Links</strong></p>
        <p>Our website may contain links to third-party APPs or websites, as
          well as plug-ins and applications. Clicking on these links or enabling
          these connections may enable third parties to gather or share data about
          you. Please note that this Privacy Policy exclusively covers the
          utilization and disclosure of information by us during your interaction
          with our Sites and Forums. We do not have control over other third-party
          APPs or websites that may be accessible through links from our APPs or
          websites, and we bear no responsibility for their privacy policies or
          the information you provide to them through our APPs or websites. As you
          navigate away from our website, we recommend that you review the privacy
          notice of each website you visit before sharing any personal information
          with them.</p>
        <p><strong>3.3 Direct Interactions</strong></p>
        <p>We have the potential to collect personal information through direct
          interactions with you. These interactions may occur through
          correspondence, email communication, text messages, applications, phone
          calls, in-person meetings, or other forms of direct engagement.</p>
        <p><strong>4. HOW OMNI USES THE DATA COLLECTED FROM YOU</strong></p>
        <p>IVISION TECH INC. utilizes your personal data for various purposes,
          including but not limited to:</p>
        <ul>
          <li><p>• Communicating with you regarding products, services, offers,
            promotions, rewards, and events, which may include offerings from third
            parties.</p></li>
        </ul>
        <ul>
          <li><p>• Registering you as a new customer, which involves verifying the
            activity of your email address(es) and enrolling you in various IVISION
            TECH INC. programs.</p></li>
          <li><p>• Managing your account(s), encompassing the tracking of usage
            history, login activities, and related account management
            tasks.</p></li>
          <li><p>• Enhancing, improving, and personalizing your overall user
            experience on our platforms.</p></li>
          <li><p>• Employing data analytics to enhance our platforms,
            products/services, marketing strategies, customer relationships, and
            user experiences by analyzing audience acquisition, behavior, and
            conversion data.</p></li>
          <li><p>• Delivering pertinent content and advertisements to you while
            measuring and understanding the effectiveness of the advertising we
            serve to you.</p></li>
          <li><p>• Maintaining our relationship with you, which includes notifying
            you about alterations to our Terms of Use Agreement, Privacy Policy, or
            other policies, rendering member and customer services, and soliciting
            your input through reviews and surveys.</p></li>
          <li><p>• Administering and facilitating your participation in promotions,
            competitions, or sweepstakes.</p></li>
          <li><p>• Offering suggestions and recommendations regarding goods or
            services that may align with your interests.</p></li>
          <li><p>• Monitoring our APP or websites to ensure compliance with our
            Terms of Use Agreement.</p></li>
          <li><p>• Responding to subpoenas, other legal processes, and government
            investigations.</p></li>
          <li><p>• Monitoring and preventing fraudulent activities and potential
            threats.</p></li>
          <li><p>• Undertaking other necessary business activities or activities
            disclosed elsewhere in this Privacy Policy.</p></li>
          <li><p>• Pursuing any other purpose either with your explicit consent or
            as allowed or mandated by applicable laws.</p></li>
        </ul>
        <p><strong>5. MARKETING</strong></p>
        <p><strong>5.1 Promotional Offers</strong></p>
        <p>We utilize your Identity, Contact, Technical, Usage, and Profile Data
          to form insights into your preferences and interests. This enables us to
          determine which products, services, and offers are likely to be most
          relevant to you. You will receive marketing communications from us if
          you have specifically requested information from us, made purchases from
          us, and provided your details during activities such as competitions,
          online campaigns, or promotions. However, you have the option to opt out
          of receiving such marketing communications.</p>
        <p><strong>5.2 Sharing of Information</strong></p>
        <p>Periodically, we may share your personal data with third parties for
          marketing and various other purposes. This may encompass sharing such
          information with other entities within the IVISION TECH INC. ecosystem.
          Examples of instances where your personal information may be disclosed
          include:</p>
        <ul>
          <li><p>• Sharing aggregated or de-identified information that cannot
            reasonably identify you with third parties, advertisers, and business
            partners. Such de-identified or aggregated data, which does not reveal
            individuals, falls outside the scope of this Privacy Policy.</p></li>
          <li><p>• Sharing with subsidiary and affiliated entities that are either
            under our control, control us, or share common control.</p></li>
          <li><p>• When you provide consent for such disclosure.</p></li>
          <li><p>• With third-party businesses offering products and services
            through our Sites and Forums.</p></li>
          <li><p>• Information you post or disclose in community forums or other
            public areas of our Sites and Forums may be accessible to other users
            and the general public. Exercise caution when deciding to share any
            personal information in such settings.</p></li>
          <li><p>• For processing your purchases, we may share your information with
            participating merchants, who may use it, including for marketing
            purposes, in accordance with their own privacy policies.</p></li>
          <li><p>• Our third-party service providers, including those responsible
            for website hosting, data analysis, payment processing, IT
            infrastructure, email delivery, text messaging, auditing, customer
            relationship management, and other services.</p></li>
          <li><p>• In connection with, or during discussions related to, any
            potential or actual mergers, acquisitions, sales, assignments, joint
            ventures, or transfers involving any part of IVISION TECH INC.'s
            business, assets, financing, or stock. This may also apply to scenarios
            such as bankruptcy proceedings.</p></li>
          <li><p>• To the extent allowed by applicable law, we may disclose
            information to copyright owners, Internet service providers, wireless
            service providers, or law enforcement agencies at our discretion. Such
            disclosures may occur without prior notice to you.</p></li>
          <li><p>• Sharing with third parties for marketing purposes, including
            those whose products or services we believe may interest you. These
            third parties may further share your information and use it for their
            marketing activities in accordance with their policies.</p></li>
          <li><p>• When we deem it necessary or appropriate: (a) to comply with
            applicable laws, including those beyond your country of residence; (b)
            to respond to legal requests; (c) to meet requests from public and
            governmental authorities, including those outside your country of
            residence; (d) to safeguard our legal rights, privacy, safety, or
            property, or those of our users, affiliates, business partners,
            employees, agents, and contractors, including the enforcement of our
            Terms of Use Agreement, this Privacy Policy, or other applicable
            policies or agreements; and (e) to seek available remedies or mitigate
            potential damages.</p></li>
          <li><p>• Additionally, please note that we may share data with our Data
            Management Company for the purpose of data analysis.</p></li>
        </ul>
        <p><strong>6. Opting Out</strong></p>
        <p>You have the option to request the cessation of marketing emails from
          us at any time. This can be done by accessing your account on the Site
          and adjusting your marketing preferences through the relevant checkboxes
          or by following the opt-out links provided in any marketing emails you
          receive. For updating your personal data and certain preferences, you
          can visit the "My Profile" section within your account on the Site. Feel
          free to reach out to us at ivisiontechinc@gmail.com for any additional
          inquiries related to privacy and your personal information.</p>
        <p><strong>7. Disclosures of Your Personal Data</strong></p>
        <p>For the purposes detailed above, it may be necessary for us to share
          your personal data with the following parties:</p>
        <ul>
          <li><p><strong>• External Third Parties</strong>, as described in the
            Glossary.</p></li>
          <li><p>• Third parties to whom we may choose to sell, transfer, or merge
            parts of our business or assets, or in connection with a bankruptcy or
            reorganization. Alternatively, we may pursue the acquisition or merger
            with other businesses. In the event of a change in our business
            structure, the new owners may utilize your personal data as outlined in
            this privacy notice. Additionally, we may disclose your personal data to
            comply with applicable laws or legal proceedings. We mandate that all
            third parties under our jurisdiction respect the security of your
            personal data and handle it in accordance with the law. We do not permit
            our third-party service providers to utilize your personal data for
            their own purposes; instead, we only authorize them to process your
            personal data for specific purposes and in accordance with our
            instructions. Furthermore, this disclosure may encompass brands that use
            Omni to customize their devices.</p></li>
        </ul>
        <p><strong>8. Data Security</strong></p>
        <p>To safeguard your personal data, we have implemented robust security
          measures designed to prevent unauthorized access, loss, alteration,
          disclosure, or misuse. We employ stringent access controls, limiting the
          availability of your personal data to employees, agents, contractors,
          and authorized third parties who require access for legitimate business
          purposes. These individuals or entities will process your personal data
          strictly in accordance with our instructions and are bound by
          confidentiality obligations. Furthermore, we have established
          comprehensive protocols to address any suspected breaches of personal
          data. In cases where legal obligations apply, we will promptly notify
          you and the relevant regulatory authorities of any such breach. Your
          data security remains our paramount concern.</p>
        <p><strong>9. Choices Regarding Your Information</strong></p>
        <p>Your privacy choices are important to us. Here's a summary of the
          options available to you:</p>
        <p><strong>9.1 Request Access to Your Personal Data (Data Subject Access
          Request)</strong><br />
          You can request a copy of the personal data we hold about you.</p>
        <p><strong>9.2 Request Correction</strong><br />
          If you find any incomplete or inaccurate personal data we hold about
          you, you can request corrections. We may need to verify the accuracy of
          any new data you provide.</p>
        <p><strong>9.3 Request Erasure of Your Personal Data</strong><br />
          You can ask us to delete or remove your personal data under certain
          circumstances, such as when there's no legal basis for us to continue
          processing it.</p>
        <p><strong>9.4 Object to Processing of Your Personal Data</strong><br />
          If you believe our processing of your personal data infringes on your
          rights, you have the right to object. This includes objecting to
          processing for direct marketing purposes.</p>
        <p><strong>9.5 Request Restriction of Processing</strong><br />
          You can ask us to temporarily halt the processing of your personal data
          in specific situations, like when you want us to verify data accuracy or
          when you're contesting the data's use.</p>
        <p><strong>9.6 Request Data Transfer</strong><br />
          Upon your request, we will provide your personal data in a structured,
          machine-readable format, either to you or a third party of your choice.
          This right applies to automated information for which you initially
          provided consent or when we used the information to fulfill a
          contract.</p>
        <p><strong>9.7 Withdraw Consent</strong><br />
          If we rely on your consent for processing your personal data, you can
          withdraw it at any time. Please note that this won't affect the legality
          of previous processing.</p>
        <p><strong>9.8 How to Exercise Your Rights</strong><br />
          To exercise any of these rights, please submit a written request or
          contact us. Generally, there are no fees for accessing your data, but we
          may charge a reasonable fee for unfounded, repetitive, or excessive
          requests. We may also request additional information to verify your
          identity and ensure data protection. We aim to respond to legitimate
          requests within one month. Complex requests may take longer, but we'll
          keep you informed. If we can't fulfill your request due to legal
          reasons, we'll notify you.</p>
        <p><strong>9.9 Your Right to Complain</strong><br />
          You have the right to lodge a complaint with your local data protection
          supervisory authority at any time. However, we encourage you to contact
          us first to address your concerns before involving the supervisory
          authority.</p>
        <p>Your privacy matters to us, and we're here to assist you in making
          informed choices about your data.</p>
        <p><strong>10. Glossary: External Third Parties</strong></p>
        <p>External Third Parties in this Privacy Policy refer to:</p>
        <ul>
          <li><p>• Service providers offering IT and system administration
            services.</p></li>
          <li><p>• Various Affiliate Networks that track your purchases made through
            the Site.</p></li>
          <li><p>• Regulators and authorities located in the United States of
            America, which may require reporting of processing activities under
            specific circumstances.</p></li>
          <li><p>• Different payout providers available on the Site, some of which
            may be based outside the US.</p></li>
          <li><p>• Depending on your marketing preferences, we may engage
            third-party email providers.</p></li>
          <li><p>• Social media publishers.</p></li>
          <li><p>• Brands or companies that have registered as OMNI business
            users.</p></li>
          <li><p>• Various tracking agencies, especially if you've joined our Site
            through a third-party referrer.</p></li>
        </ul>
        <p><strong>11. Changes to This Privacy Policy</strong></p>
        <p>This Privacy Policy is current as of the effective date mentioned
          above. IVISION TECH INC. reserves the right to amend this privacy policy
          in accordance with applicable privacy laws and principles. If any
          changes are made, we will inform you by updating the date at the top of
          this Privacy Policy. In some cases, we may also provide additional
          notice, such as adding a statement to the homepages of our APP or
          websites or sending you an email notification.</p>
        <p><strong>12. California Residents' Privacy Rights and California
          Consumer Privacy Act (CCPA)</strong></p>
        <p>If you are a California resident and have an established business
          relationship with us, California Privacy laws grant you the following
          rights:</p>
        <ul>
          <li><p>• Request specific details about how your information is shared
            with third parties, including affiliates, for their own direct marketing
            purposes.</p></li>
          <li><p>• Request information about the categories of personal information
            collected and the purposes for which these categories are used.</p></li>
          <li><p>• Request the deletion of any personal information we have
            collected from you.</p></li>
          <li><p>• Direct us not to sell (i.e., "opt out") your personal information
            to a third party in the future.</p></li>
          <li><p>• Request the deletion of any personal information we have
            collected from you.</p></li>
          <li><p>• If you are under 21 years old and a registered user of any of our
            Sites and Forums, request the removal of content or information that you
            have posted. Please note that fulfilling this request may not guarantee
            complete or comprehensive removal (e.g., if the content or information
            has been reposted by another user).</p></li>
          <li><p>• For California residents, we will promptly delete information
            associated with any account we obtain actual knowledge is associated
            with a registered user who is not over twenty-one (21) years old. We do
            not sell personal information of users under the age of twenty-one
            (21).</p></li>
        </ul>


      </div>

      <div v-if="$route.params.type === 2" class="privacy-policy">

        <p><strong>TERMS OF SERVICE</strong></p>
        <p>Last updated December 15, 2023</p>
        <p><strong>AGREEMENT TO LEGAL TERMS</strong></p>
        <p>We are IVISION TECH INC.  ("<strong>Company</strong>,"
          "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>"), a
          company registered and located at 11818 Clark St., Building A, Arcadia,
          California 91006.</p>
        <p>We operate the mobile application OMNI
          Connector (the "<strong>App</strong>"), as well as other related
          products and services that refer or link to these legal terms
          (the "<strong>Legal Terms</strong>") (collectively,
          the "<strong>Services</strong>").</p>
        <p>You can contact us by phone at (626) 256-3449, email
          at ivisiontechinc@gmail.com, or by mail to 11818 Clark St., Building A,
          Arcadia, California 91006, United States.</p>
        <p>By downloading the App and connecting your vape device to your phone,
          via NFC or otherwise, or by using the App, you agree to abide by these
          Legal Terms. Users may be required to create an account to gain access
          to certain features of the App; however, creation of an account is not a
          prerequisite to your agreement to abide by these Legal Terms.</p>
        <p>These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity
          ("<strong>you</strong>" and the possessive <strong>“your”</strong>),
          and  IVISION TECH INC., concerning your access to and use of the
          Services. You agree that by accessing the Services, you have read,
          understood, and agreed to be bound by all of these Legal Terms. IF YOU
          DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY
          PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.</p>
        <p>Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated herein
          by reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Legal Terms from time to time. We will
          alert you about any changes by updating the "Last updated" date of these
          Legal Terms, and you waive any right to receive specific notice of each
          such change. It is your responsibility to periodically review these
          Legal Terms to stay informed of updates. You will be subject to, and
          will be deemed to have been made aware of and to have accepted, the
          changes in any revised Legal Terms by your continued use of the Services
          after the date such revised Legal Terms are posted.</p>
        <p>The Services are intended for users who are at least 21 years old.
          Persons under the age of 21 are not permitted to use or register for the
          Services.</p>
        <p>We recommend that you print a copy of these Legal Terms for your
          records.</p>
        <p><strong>TABLE OF CONTENTS</strong></p>
        <ol type="1">
          <li><p>1.OUR SERVICES</p></li>
          <li><p>2.INTELLECTUAL PROPERTY RIGHTS</p></li>
          <li><p>3.USER REPRESENTATIONS</p></li>
          <li><p>4.USER REGISTRATION</p></li>
          <li><p>5.PROHIBITED ACTIVITIES</p></li>
          <li><p>6.MOBILE APPLICATION LICENSE</p></li>
          <li><p>7.VAPING CUSTOMIZATION</p></li>
          <li><p>8.PRIVACY POLICY</p></li>
          <li><p>9.COPYRIGHT INFRINGEMENTS</p></li>
          <li><p>10.TERM AND TERMINATION</p></li>
          <li><p>11.MODIFICATIONS AND INTERRUPTIONS</p></li>
          <li><p>12.GOVERNING LAW</p></li>
          <li><p>13.DISPUTE RESOLUTION</p></li>
          <li><p>14.CORRECTIONS</p></li>
          <li><p>15.DISCLAIMER</p></li>
          <li><p>16.LIMITATIONS OF LIABILITY</p></li>
          <li><p>17.INDEMNIFICATION</p></li>
          <li><p>18.USER DATA</p></li>
          <li><p>19.ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</p></li>
          <li><p>20.CALIFORNIA USERS AND RESIDENTS</p></li>
          <li><p>21.MISCELLANEOUS</p></li>
          <li><p>22.CONTACT US</p></li>
        </ol>
        <ol type="1">
          <li><p><strong>1.OUR SERVICES</strong></p></li>
        </ol>
        <p>The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws, if
          and to the extent local laws are applicable.</p>
        <p>The Services are not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.), so
          if your interactions would be subjected to such laws, you may not use
          the Services. You may not use the Services in a way that would violate
          the Gramm-Leach-Bliley Act (GLBA).</p>
        <ol start="2" type="1">
          <li><p><strong>2.INTELLECTUAL PROPERTY RIGHTS</strong></p></li>
        </ol>
        <p><strong>Our Intellectual Property</strong></p>
        <p>We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and graphics
          in the Services (collectively, the "Content"), as well as the
          trademarks, service marks, and logos contained therein
          (the "Marks").</p>
        <p>Our Content and Marks are protected by copyright and trademark laws
          (and various other intellectual property rights and unfair competition
          laws) and treaties in the United States and around the world.</p>
        <p>The Content and Marks are provided in or through the Services "AS
          IS" for your personal, non-commercial use or internal business
          purpose only.</p>
        <p><strong>Your Use of Our Services</strong></p>
        <p>Subject to your compliance with these Legal Terms, including
          the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive,
          non-transferable, revocable license to:</p>
        <ul>
          <li><p>access the Services; and</p></li>
          <li><p>download or print a copy of any portion of the Content to which
            you have properly gained access.</p></li>
        </ul>
        <p>Except as set out in this section or elsewhere in our Legal Terms, no
          part of the Services and no Content or Marks may be copied, reproduced,
          aggregated, republished, uploaded, posted, publicly displayed, encoded,
          translated, transmitted, distributed, sold, licensed, or otherwise
          exploited for any commercial purpose whatsoever, without our express
          prior written permission.</p>
        <p>If you wish to make any use of the Services, Content, or Marks other
          than as set out in this section or elsewhere in our Legal Terms, please
          address your request to:  ivisiontechinc@gmail.com. If we ever grant you
          the permission to post, reproduce, or publicly display any part of our
          Services or Content, you must identify us as the owners or licensors of
          the Services, Content, or Marks and ensure that any copyright or
          proprietary notice appears or is visible on posting, reproducing, or
          displaying our Content.</p>
        <p>We reserve all rights not expressly granted to you in and to the
          Services, Content, and Marks.</p>
        <p>Any breach of these Intellectual Property Rights will constitute a
          material breach of our Legal Terms and your right to use our Services
          will terminate immediately.</p>
        <p><strong>Your Submissions</strong></p>
        <p>Please review this section and the "PROHIBITED ACTIVITIES" section
          carefully prior to using our Services to understand the (a) rights you
          give us and (b) obligations you have when you post or upload any content
          through the Services.</p>
        <p><strong>Submissions:</strong> By directly sending us any question,
          comment, suggestion, idea, feedback, or other information about the
          Services ("Submissions"), you agree to assign to us all intellectual
          property rights in such Submission. You agree that we shall own this
          Submission and be entitled to its unrestricted use and dissemination for
          any lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you.</p>
        <p><strong>Copyright Infringement</strong></p>
        <p>We respect the intellectual property rights of others. If you believe
          that any material available on or through the Services infringes upon
          any copyright you own or control, please immediately refer to
          the "COPYRIGHT INFRINGEMENTS" section below.</p>
        <ol start="3" type="1">
          <li><p><strong>3.USER REPRESENTATIONS</strong></p></li>
        </ol>
        <p>By using the Services, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current, and
          complete; (2) you will maintain the accuracy of such information and
          promptly update such registration information as necessary; (3) you have
          the legal capacity and you agree to comply with these Legal Terms; (4)
          you are not a minor in the jurisdiction in which you reside; (5) you
          will not access the Services through automated or non-human means,
          whether through a bot, script or otherwise; (6) you will not use the
          Services for any illegal or unauthorized purpose; and (7) your use of
          the Services will not violate any applicable law or regulation.</p>
        <p>If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Services (or
          any portion thereof).</p>
        <ol start="4" type="1">
          <li><p><strong>4.USER REGISTRATION</strong></p></li>
        </ol>
        <p>You may be required to register to use the Services. You agree to
          keep your password confidential and will be responsible for all use of
          your account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.</p>
        <ol start="5" type="1">
          <li><p><strong>5.PROHIBITED ACTIVITIES</strong></p></li>
        </ol>
        <p>You may not access or use the Services for any purpose other than
          that for which we make the Services available. The Services may not be
          used in connection with any commercial endeavors except those that are
          specifically endorsed or approved by us in writing.</p>
        <p>As a user of the Services, you agree not to:</p>
        <ul>
          <li><p>• Systematically retrieve data or other content from the Services
            to create or compile, directly or indirectly, a collection, compilation,
            database, or directory without written permission from us.</p></li>
          <li><p>• Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.</p></li>
          <li><p>• Circumvent, disable, or otherwise interfere with security-related
            features of the Services, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use of
            the Services and/or the Content contained therein.</p></li>
          <li><p>• Disparage, tarnish, or otherwise harm, in our opinion, us and/or
            the Services.</p></li>
          <li><p>• Use any information obtained from the Services in order to
            harass, abuse, or harm another person.</p></li>
          <li><p>• Make improper use of our support services or submit false reports
            of abuse or misconduct.</p></li>
          <li><p>• Use the Services in a manner inconsistent with any applicable
            laws or regulations.</p></li>
          <li><p>• Engage in unauthorized framing of or linking to the
            Services.</p></li>
          <li><p>• Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Services or modifies, impairs, disrupts, alters, or interferes with the
            use, features, functions, operation, or maintenance of the
            Services.</p></li>
          <li><p>• Engage in any automated use of the system, such as using scripts
            to send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.</p></li>
          <li><p>• Delete the copyright or other proprietary rights notice from any
            Content.</p></li>
          <li><p>• Attempt to impersonate another user or person or use the username
            of another user.</p></li>
          <li><p>• Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other
            similar devices (sometimes referred to as "spyware" or "passive
            collection mechanisms" or "pcms").</p></li>
          <li><p>• Interfere with, disrupt, or create an undue burden on the
            Services or the networks or services connected to the Services.</p></li>
          <li><p>• Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Services to you.</p></li>
          <li><p>• Attempt to bypass any measures of the Services designed to
            prevent or restrict access to the Services, or any portion of the
            Services.</p></li>
          <li><p>• Copy or adapt the Services' software, including but not limited
            to Flash, PHP, HTML, JavaScript, or other code.</p></li>
          <li><p>• Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or in
            any way making up a part of the Services.</p></li>
          <li><p>• Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated system,
            including without limitation, any spider, robot, cheat utility, scraper,
            or offline reader that accesses the Services, or use or launch
            any unauthorized script or other software.</p></li>
          <li><p>• Use a buying agent or purchasing agent to make purchases on the
            Services.</p></li>
          <li><p>• Make any unauthorized use of the Services, including collecting
            usernames and/or email addresses of users by electronic or other means
            for the purpose of sending unsolicited email, or creating user accounts
            by automated means or under false pretenses.</p></li>
          <li><p>• Use the Services as part of any effort to compete with us or
            otherwise use the Services and/or the Content for any
            revenue-generating endeavor or commercial enterprise.</p></li>
          <li><p>• Sell or otherwise transfer your profile.</p></li>
          <li><p>• Use the Services to advertise or offer to sell goods and
            services.</p></li>
        </ul>
        <ol start="6" type="1">
          <li><p><strong>6.MOBILE APPLICATION LICENSE</strong></p></li>
        </ol>
        <p><strong>Use License</strong></p>
        <p>If you access the Services, then we grant you a revocable,
          non-exclusive, non-transferable, limited right to install and use the
          App on wireless electronic devices owned or controlled by you, and to
          access and use the App on such devices strictly in accordance with the
          terms and conditions of this mobile application license contained in
          these Legal Terms. You shall not: (1) except as permitted by applicable
          law, decompile, reverse engineer, disassemble, attempt to derive the
          source code of, or decrypt the App; (2) make any modification,
          adaptation, improvement, enhancement, translation, or derivative work
          from the App; (3) violate any applicable laws, rules, or regulations in
          connection with your access or use of the App; (4) remove, alter, or
          obscure any proprietary notice (including any notice of copyright or
          trademark) posted by us or the licensors of the App; (5) use the App for
          any revenue-generating endeavor, commercial enterprise, or other purpose
          for which it is not designed or intended; (6) make the App available
          over a network or other environment permitting access or use by multiple
          devices or users at the same time; (7) use the App for creating a
          product, service, or software that is, directly or indirectly,
          competitive with or in any way a substitute for the App; (8) use the App
          to send automated queries to any website or to send any unsolicited
          commercial email; or (9) use any proprietary information or any of our
          interfaces or our other intellectual property in the design,
          development, manufacture, licensing, or distribution of any
          applications, accessories, or devices for use with the App.</p>
        <p><strong>Apple and Android Devices</strong></p>
        <p>The following terms apply when you use the App obtained from either
          the Apple Store or Google Play (each an "App Distributor") to access the
          Services: (1) the license granted to you for our App is limited to a
          non-transferable license to use the application on a device
          that utilizes the Apple iOS or Android operating systems, as applicable,
          and in accordance with the usage rules set forth in the applicable App
          Distributor’s terms of service; (2) we are responsible for providing any
          maintenance and support services with respect to the App as specified in
          the terms and conditions of this mobile application license contained in
          these Legal Terms or as otherwise required under applicable law, and you
          acknowledge that each App Distributor has no obligation whatsoever to
          furnish any maintenance and support services with respect to the App;
          (3) in the event of any failure of the App to conform to any applicable
          warranty, you may notify the applicable App Distributor, and the App
          Distributor, in accordance with its terms and policies, may refund the
          purchase price, if any, paid for the App, and to the maximum extent
          permitted by applicable law, the App Distributor will have no other
          warranty obligation whatsoever with respect to the App; (4) you
          represent and warrant that (i) you are not located in a country that is
          subject to a US government embargo, or that has been designated by the
          US government as a "terrorist supporting" country and (ii) you are not
          listed on any US government list of prohibited or restricted parties;
          (5) you must comply with applicable third-party terms of agreement when
          using the App, e.g., if you have a VoIP application, then you must not
          be in violation of their wireless data service agreement when using the
          App; and (6) you acknowledge and agree that the App Distributors are
          third-party beneficiaries of the terms and conditions in this mobile
          application license contained in these Legal Terms, and that each App
          Distributor will have the right (and will be deemed to have accepted the
          right) to enforce the terms and conditions in this mobile
          application license contained in these Legal Terms against you as a
          third-party beneficiary thereof.</p>
        <ol start="7" type="1">
          <li><p><strong>6.VAPING CUSTOMIZATION</strong></p></li>
        </ol>
        <p>You acknowledge your ability to customize parameters, such as power
          curve of a vape device, and you acknowledge your ability to view
          information such as a vape device’s battery life, oil level, power
          curve, oil flavor, oil brand through the App. We explicitly disclaim,
          and you expressly agree to release us from, any and all liability
          resulting from any customization of parameters of a vape device or from
          viewing or relying on any information related to a vape device.</p>
        <p>The App enables you to obtain convenient information including but
          not limited to the battery life, oil level, power curve, oil flavor,
          brand of a vape device. You acknowledge that the information may not be
          entirely accurate, and we assume no liability for any inaccuracies. Any
          reliance on battery life information from the App or otherwise shall be
          at your own risk.</p>
        <p>While we aim to provide accurate information and functionality
          through the App, you acknowledge and accept that errors, bugs, or
          interruptions may occur. We explicitly disclaim, and you expressly agree
          to release us from, any and all liability for direct or indirect damages
          resulting from errors, bugs, or interruptions in service.</p>
        <p>Your use of the App and any customization of vape device parameters
          shall be at your sole discretion and risk. You are strongly urged to
          exercise caution, particularly when customizing settings, and to adhere
          strictly to any and all applicable safety guidelines, including without
          limitation, those provided by us.</p>
        <p>You are obligated to comply with all applicable laws and regulations.
          We explicitly disclaim, and you expressly agree to release us from, any
          and all liability resulting from your actions that violate any law or
          regulation.</p>
        <ol start="8" type="1">
          <li><p><strong>8.PRIVACY POLICY</strong></p></li>
        </ol>
        <p>The Services are governed by the OMNI Privacy Policy, as set forth in
          a separate document. You acknowledge and agree to abide by the
          provisions set forth in the OMNI Privacy Policy.</p>
        <ol start="9" type="1">
          <li><p><strong>9.COPYRIGHT INFRINGEMENT</strong></p></li>
        </ol>
        <p>We respect the intellectual property rights of others. If you believe
          that any material available on or through the Services infringes upon
          any copyright you own or control, please immediately notify us using the
          contact information provided below (a "Notification"). A copy of your
          Notification will be sent to the person who posted or stored the
          material addressed in the Notification. Please be advised that pursuant
          to applicable law you may be held liable for damages if you make
          material misrepresentations in a Notification. Thus, if you are not sure
          that material located on or linked to by the Services infringes your
          copyright, you should consider first contacting an attorney.</p>
        <ol start="10" type="1">
          <li><p><strong>10.TERM AND TERMINATION</strong></p></li>
        </ol>
        <p>These Legal Terms shall remain in full force and effect while you use
          the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
          WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
          LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
          CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
          INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
          OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
          REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
          OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
          ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>
        <p>If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your name,
          a fake or borrowed name, or the name of any third party, even if you may
          be acting on behalf of the third party. In addition to terminating or
          suspending your account, we reserve the right to take appropriate legal
          action, including without limitation pursuing civil, criminal, and
          injunctive redress.</p>
        <ol start="11" type="1">
          <li><p><strong>11.MODIFICATIONS AND INTERRUPTIONS</strong></p></li>
        </ol>
        <p>We reserve the right to change, modify, or remove the contents of the
          Services at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on our
          Services. We will not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the
          Services.</p>
        <p>We cannot guarantee the Services will be available at all times. We
          may experience hardware, software, or other problems or need to perform
          maintenance related to the Services, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Services at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Services during any downtime or
          discontinuance of the Services or the App. Nothing in these Legal Terms
          will be construed to obligate us to maintain and support the Services or
          to supply any corrections, updates, or releases in connection
          therewith.</p>
        <ol start="12" type="1">
          <li><p><strong>12.GOVERNING LAW</strong></p></li>
        </ol>
        <p>These Legal Terms and your use of the Services are governed by and
          construed in accordance with the laws of the State
          of California applicable to agreements made and to be entirely performed
          within the State of California, without regard to its conflict of law
          principles.</p>
        <ol start="13" type="1">
          <li><p><strong>13. DISPUTE RESOLUTION</strong></p></li>
        </ol>
        <p><strong>Binding Arbitration</strong></p>
        <p>If the parties are unable to resolve a dispute through informal
          negotiations, the dispute (except those disputes expressly excluded
          below) will be finally and exclusively resolved by binding arbitration.
          YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO
          SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced
          and conducted under the Commercial Arbitration Rules of the American
          Arbitration Association ("AAA") and, where appropriate, the AAA’s
          Supplementary Procedures for Consumer Related Disputes ("AAA Consumer
          Rules"), both of which are available at the American Arbitration
          Association (AAA) website. Your arbitration fees and your share of
          arbitrator compensation shall be governed by the AAA Consumer Rules and,
          where appropriate, limited by the AAA Consumer Rules. The arbitration
          may be conducted in person, through the submission of documents, by
          phone, or online. The arbitrator will make a decision in writing, but
          need not provide a statement of reasons unless requested by either
          party. The arbitrator must follow applicable law, and any award may be
          challenged if the arbitrator fails to do so. Except where otherwise
          required by the applicable AAA rules or applicable law, the arbitration
          will take place in California. Except as otherwise provided herein, the
          parties may litigate in court to compel arbitration, stay proceedings
          pending arbitration, or to confirm, modify, vacate, or enter judgment on
          the award entered by the arbitrator.</p>
        <p>If for any reason, a dispute proceeds in court rather than
          arbitration, the dispute shall be commenced or prosecuted in the state
          or federal courts located in the State of California, and the parties
          hereby consent to, and waive all defenses of lack of personal
          jurisdiction, and forum non conveniens with respect to venue and
          jurisdiction in such state and federal courts. Application of the United
          Nations Convention on Contracts for the International Sale of Goods and
          the Uniform Computer Information Transaction Act (UCITA) are excluded
          from these Legal Terms.</p>
        <p>In no event shall any dispute brought by any party related in any way
          to the Services be commenced more than one (1) year after the cause of
          action arose. If this provision is found to be illegal or unenforceable,
          then neither party will elect to arbitrate any dispute falling within
          that portion of this provision found to be illegal or unenforceable and
          such dispute shall be decided by a court of competent jurisdiction
          within the courts listed for jurisdiction above, and the parties agree
          to submit to the personal jurisdiction of that court.</p>
        <p><strong>Restrictions</strong></p>
        <p>You agree that any arbitration shall be limited to the dispute
          between the parties. To the full extent permitted by law, (a) no
          arbitration shall be joined with any other proceeding; (b) there is no
          right or authority for any dispute to be arbitrated on a class-action
          basis or to utilize class action procedures; and (c) there is no right
          or authority for any dispute to be brought in a purported representative
          capacity on behalf of the general public or any other persons.</p>
        <p><strong>Exceptions to Arbitration</strong></p>
        <p>The parties agree that the following disputes are not subject to the
          above provisions concerning binding arbitration: (a) any dispute seeking
          to enforce or protect, or concerning the validity of, any of the
          intellectual property rights of a party; (b) any dispute related to, or
          arising from, allegations of theft, piracy, invasion of privacy,
          or unauthorized use; and (c) any claim for injunctive relief. If this
          provision is found to be illegal or unenforceable, then neither party
          will elect to arbitrate any dispute falling within that portion of this
          provision found to be illegal or unenforceable and such dispute shall be
          decided by a court of competent jurisdiction within the courts listed
          for jurisdiction above, and the parties agree to submit to the personal
          jurisdiction of that court.</p>
        <ol start="14" type="1">
          <li><p><strong>14.CORRECTIONS</strong></p></li>
        </ol>
        <p>There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.</p>
        <ol start="15" type="1">
          <li><p><strong>15.DISCLAIMER</strong></p></li>
        </ol>
        <p>THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
          IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
          WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
          COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR
          MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO
          LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
          OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
          SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
          INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
          TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN
          HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES
          BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT
          AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
          OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
          AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
          ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
          BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
          WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
          ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
          FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS
          OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
          THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
          BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
        <ol start="16" type="1">
          <li><p><strong>16.LIMITATIONS OF LIABILITY</strong></p></li>
        </ol>
        <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
          OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
          OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT
          PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY
          CAUSE OF ACTION ARISING OR $1,000.00 USD. CERTAIN US STATE LAWS AND
          INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
          EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
          SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
          YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
        <ol start="17" type="1">
          <li><p><strong>17.INDEMNIFICATION</strong></p></li>
        </ol>
        <p>You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the Services; (3) breach of these Legal Terms;
          (4) any breach of your representations and warranties set forth in these
          Legal Terms; (5) your violation of the rights of a third party,
          including but not limited to intellectual property rights; or (6) any
          overt harmful act toward any other user of the Services with whom you
          connected via the Services. Notwithstanding the foregoing, we reserve
          the right, at your expense, to assume the exclusive defense and control
          of any matter for which you are required to indemnify us, and you agree
          to cooperate, at your expense, with our defense of such claims. We will
          use reasonable efforts to notify you of any such claim, action, or
          proceeding which is subject to this indemnification upon becoming aware
          of it.</p>
        <ol start="18" type="1">
          <li><p><strong>18.USER DATA</strong></p></li>
        </ol>
        <p>We will maintain certain data that you transmit to the Services for
          the purpose of managing the performance of the Services, as well as data
          relating to your use of the Services. Although we may perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          the Services. You agree that we shall have no liability to you for any
          loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such
          data.</p>
        <ol start="19" type="1">
          <li><p><strong>19.ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
            SIGNATURES</strong></p></li>
        </ol>
        <p>Visiting our website, using the Services, sending us emails, and
          completing online forms constitute electronic communications. You
          consent to receive electronic communications, and you agree that all
          agreements, notices, disclosures, and other communications we provide to
          you electronically, via email and on the Services, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS,
          AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
          TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You
          hereby waive any rights or requirements under any statutes, regulations,
          rules, ordinances, or other laws in any jurisdiction which require an
          original signature or delivery or retention of non-electronic records,
          or to payments or the granting of credits by any means other than
          electronic means.</p>
        <ol start="20" type="1">
          <li><p><strong>20.CALIFORNIA USERS AND RESIDENTS</strong></p></li>
        </ol>
        <p>If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing at
          1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by
          telephone at (800) 952-5210 or (916) 445-1254.</p>
        <ol start="21" type="1">
          <li><p><strong>21.MISCELLANEOUS</strong></p></li>
        </ol>
        <p>These Legal Terms and any policies or operating rules posted by us on
          the Services or in respect to the Services constitute the entire
          agreement and understanding between you and us. Our failure to exercise
          or enforce any right or provision of these Legal Terms shall not operate
          as a waiver of such right or provision. These Legal Terms operate to the
          fullest extent permissible by law. We may assign any or all of our
          rights and obligations to others at any time. We shall not be
          responsible or liable for any loss, damage, delay, or failure to act
          caused by any cause beyond our reasonable control. If any provision or
          part of a provision of these Legal Terms is determined to be unlawful,
          void, or unenforceable, that provision or part of the provision is
          deemed severable from these Legal Terms and does not affect the validity
          and enforceability of any remaining provisions. There is no joint
          venture, partnership, employment or agency relationship created between
          you and us as a result of these Legal Terms or use of the Services. You
          agree that these Legal Terms will not be construed against us by virtue
          of having drafted them. You hereby waive any and all defenses you may
          have based on the electronic form of these Legal Terms and the lack of
          signing by the parties hereto to execute these Legal Terms.</p>
        <ol start="22" type="1">
          <li><p><strong>22.CONTACT US</strong></p></li>
        </ol>
        <p>In order to resolve a complaint regarding the Services, or to receive
          further information regarding use of the Services, please contact us
          at:</p>
        <p>IVISION TECH INC.</p>
        <p>11818 Clark St., Building A, Arcadia, CA 91006</p>
        <p>United States</p>
        <p>Phone: (626) 256-3449</p>
        <p>Email:  ivisiontechinc@gmail.com</p>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      content: null
    };
  },
  mounted() {
  },
  methods: {

    onClickHeaderReturn() {
      this.$router.go(-1);
    }
  }
};
</script>


<style scoped lang="less">
p {
  margin: 10px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #545554;

    .header-return {
      position: absolute;
      left: 10px;
    }
  }

  .privacy-policy {
    padding: 5px;

    .item {
      font-size: 14px;
    }

    .bold {
      font-weight: bold;
    }

    .title {
      font-size: 24px;
    }

    .subtitle {
      font-size: 18px;
    }
  }
}


.pd-10 {
  padding: 0 10px;
}

::v-deep ol {
  list-style: disc !important;
}

::v-deep ul {
  list-style: decimal !important;
}
::v-deep li {
  display:list-item;
}
</style>